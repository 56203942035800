import axios, { AxiosResponse } from "axios";

export type User = {
  name: string;
  id: string;
  roles: Array<string>;
  type: string;
};

let url = process.env.REACT_APP_API_URL || `http://localhost:8080`;

let apiClient = axios.create({
  baseURL: url,
});

const MetricsAPI = {
  setToken: (token): void => {
    apiClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  },
  getUser: (): Promise<AxiosResponse<User>> => {
    return apiClient.get<User>(`/v1/user`);
  },
  getDashboard: (
    shouldRefresh: boolean,
    rel: number,
    start?: string,
    end?: string
  ): Promise<AxiosResponse<any>> => {
    return apiClient.get<any>(`/v1/data/views/dashboard`, {
      params: {
        rel,
        start,
        end,
      },
      headers: {
        "X-Cache-Refresh": shouldRefresh,
      },
    });
  },
  getClientViews: (
    clientId: string,
    fields: string,
    limit: number,
    rel: string,
    shouldRefresh: boolean
  ): Promise<AxiosResponse<any>> => {
    return apiClient.get<any>(`/v1/data/views/client/${clientId}`, {
      params: {
        limit,
        fields,
        rel,
      },
      headers: {
        "X-Cache-Refresh": shouldRefresh,
      },
    });
  },
  getClass: (
    classId: string,
    shouldRefresh: boolean,
    rel?: string,
    start?: string,
    end?: string
  ): Promise<AxiosResponse<any>> => {
    return apiClient.get<any>(`/v1/data/views/class/${classId}`, {
      params: {
        rel,
        start,
        end,
      },
      headers: {
        "X-Cache-Refresh": shouldRefresh,
      },
    });
  },
  getClassPayout: (
    shouldRefresh: boolean,
    rel: number,
    start?: string,
    end?: string,
    teacherId?: string
  ): Promise<AxiosResponse<any>> => {
    return apiClient.get<any>(`/v1/data/views/class/payout`, {
      params: {
        rel,
        start,
        end,
        teacherId,
      },
      headers: {
        "X-Cache-Refresh": shouldRefresh,
      },
    });
  },
  getTeacherPayout: (
    shouldRefresh: boolean,
    rel: number,
    start?: string,
    end?: string
  ): Promise<AxiosResponse<any>> => {
    return apiClient.get<any>(`/v1/data/views/teacher/payout`, {
      params: {
        rel,
        start,
        end,
      },
      headers: {
        "X-Cache-Refresh": shouldRefresh,
      },
    });
  },
  getClientPayout: (
    shouldRefresh: boolean,
    rel: number,
    start?: string,
    end?: string
  ): Promise<AxiosResponse<any>> => {
    return apiClient.get<any>(`/v1/data/views/client/payout`, {
      params: {
        rel,
        start,
        end,
      },
      headers: {
        "X-Cache-Refresh": shouldRefresh,
      },
    });
  },
};

export default MetricsAPI;
