import type { FC, ReactNode } from "react";
import { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { useAuth0 } from "@auth0/auth0-react";
import SplashScreen from "./SplashScreen";
import MetricsAPI from "../utils/MetricsAPI";
import ServerError from "src/pages/ServerError";
import Forbidden from "src/pages/Forbidden";

interface AuthGuardProps {
  children: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = (props) => {
  const { children } = props;
  const auth = useAuth0();
  const location = useLocation();
  const [error, setError] = useState(null);
  const [requestedLocation, setRequestedLocation] = useState(null);
  const [accessToken, setAccessToken] = useState(null);

  const setToken = (token: string) => {
    if (auth.error) {
      console.error(auth.error);
      return;
    }
    setAccessToken(token);
    MetricsAPI.setToken(token);
  };

  if (error) {
    if (typeof error == "string") {
      return <ServerError message={error} reason="" />;
    } else {
      return <ServerError message={error.toString()} reason="" />;
    }
  }

  if (!auth.isAuthenticated) {
    auth.loginWithRedirect();

    return <SplashScreen />;
  } else {
    if (window.location.host.includes("localhost")) {
      auth
        .getAccessTokenWithPopup()
        .then(setToken)
        .catch((err) => {
          err.message = "getAccessTokenWithPopup: " + err.message;
          if (err) {
            setError(err);
          }
        });
    } else {
      auth
        .getAccessTokenSilently()
        .then(setToken)
        .catch((err) => {
          err.message = "getAccessTokenSilently: " + err.message;
          if (err.error.includes("Consent required")) {
            auth.getAccessTokenWithPopup().then(setToken);
          } else {
            setError(err);
          }
        });
    }

    if (!accessToken) {
      return <SplashScreen />;
    } else {
      let body = JSON.parse(atob(accessToken.split(".")[1]));
      if (!body.permissions) {
        return (
          <ServerError
            reason={null}
            message="Application misconfigured, no `permissions` on accessToken."
          />
        );
      } else if (!body.permissions.includes("analytics")) {
        return <Forbidden />;
      }
    }
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
