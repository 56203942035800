import type { FC } from "react";
import type { Theme } from "@material-ui/core";
import { experimentalStyled } from "@material-ui/core/styles";
import type { SxProps } from "@material-ui/system";

interface LogoProps {
  sx?: SxProps<Theme>;
}

const LogoRoot = experimentalStyled("svg")``;

const Logo: FC<LogoProps> = (props) => (
  <LogoRoot
    height="52"
    version="1.1"
    viewBox="0 0 457 457"
    width="52"
    {...props}
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group" fill="#8FB594" fillRule="nonzero">
        <path
          d="M228.421725,104.298916 C160.05615,104.298916 104.426025,159.864429 104.426025,228.162251 C104.426025,296.448424 160.05615,352.013937 228.421725,352.013937 C296.798963,352.013937 352.429088,296.448424 352.429088,228.162251 C352.429088,159.864429 296.798963,104.298916 228.421725,104.298916 M228.421725,366.505238 C152.055675,366.505238 89.917875,304.451256 89.917875,228.162251 C89.917875,151.873247 152.055675,89.8076163 228.421725,89.8076163 C304.799438,89.8076163 366.937238,151.873247 366.937238,228.162251 C366.937238,304.451256 304.799438,366.505238 228.421725,366.505238"
          id="Shape"
        ></path>
        <path
          d="M228.421725,53.1075845 C131.797913,53.1075845 53.181,131.633188 53.181,228.156426 C53.181,324.679666 131.797913,403.216919 228.421725,403.216919 C325.0572,403.216919 403.685775,324.679666 403.685775,228.156426 C403.685775,131.633188 325.0572,53.1075845 228.421725,53.1075845 M228.421725,413.235019 C126.258225,413.235019 43.127925,330.212919 43.127925,228.156426 C43.127925,126.111583 126.258225,43.0778345 228.421725,43.0778345 C330.60855,43.0778345 413.727188,126.111583 413.727188,228.156426 C413.727188,330.212919 330.60855,413.235019 228.421725,413.235019"
          id="Shape"
        ></path>
        <g id="Shape">
          <path d="M228.421725,7.72325694 C106.7352,7.72325694 7.7439,106.599585 7.7439,228.156426 C7.7439,349.689971 106.7352,448.589597 228.421725,448.589597 C350.119912,448.589597 449.111213,349.689971 449.111213,228.156426 C449.111213,106.599585 350.119912,7.72325694 228.421725,7.72325694 M228.421725,456.312854 C102.478388,456.312854 3.55271368e-15,353.965137 3.55271368e-15,228.156426 C3.55271368e-15,102.347716 102.478388,7.10542736e-15 228.421725,7.10542736e-15 C354.376725,7.10542736e-15 456.84345,102.347716 456.84345,228.156426 C456.84345,353.965137 354.376725,456.312854 228.421725,456.312854"></path>
          <polygon points="191.820937 175.010218 191.820937 281.302636 293.326164 224.169013"></polygon>
        </g>
      </g>
    </g>
  </LogoRoot>
);

export default Logo;
