import { Suspense, lazy } from "react";
import type {} from "react-router";
import { Navigate } from "react-router-dom";
// import { Navigate } from 'react-router-dom';
import AuthGuard from "./components/AuthGuard";
// import BlogLayout from './components/blog/BlogLayout';
// import BrowseLayout from './components/BrowseLayout';
import ViewsLayout from "./components/dashboard/DashboardLayout";
// import DocsLayout from './components/docs/DocsLayout';
import LoadingScreen from "./components/LoadingScreen";
// import MainLayout from './components/MainLayout';

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Browse pages

// const Browse = Loadable(lazy(() => import('./pages/browse/Browse')));
// const BrowseButtons = Loadable(lazy(() => import('./pages/browse/BrowseButtons')));
// const BrowseCharts = Loadable(lazy(() => import('./pages/browse/BrowseCharts')));
// const BrowseColors = Loadable(lazy(() => import('./pages/browse/BrowseColors')));
// const BrowseDetailLists = Loadable(lazy(() => import('./pages/browse/BrowseDetailLists')));
// const BrowseForms = Loadable(lazy(() => import('./pages/browse/BrowseForms')));
// const BrowseGridLists = Loadable(lazy(() => import('./pages/browse/BrowseGridLists')));
// const BrowseGroupedLists = Loadable(lazy(() => import('./pages/browse/BrowseGroupedLists')));
// const BrowseInputs = Loadable(lazy(() => import('./pages/browse/BrowseInputs')));
// const BrowseModals = Loadable(lazy(() => import('./pages/browse/BrowseModals')));
// const BrowseQuickStats = Loadable(lazy(() => import('./pages/browse/BrowseQuickStats')));
// const BrowseTables = Loadable(lazy(() => import('./pages/browse/BrowseTables')));
// const BrowseTypography = Loadable(lazy(() => import('./pages/browse/BrowseTypography')));

// Authentication pages

// const Login = Loadable(lazy(() => import("./pages/authentication/Login")));
// const PasswordRecovery = Loadable(
//   lazy(() => import("./pages/authentication/PasswordRecovery"))
// );
// const PasswordReset = Loadable(
//   lazy(() => import("./pages/authentication/PasswordReset"))
// );
// const Register = Loadable(
//   lazy(() => import("./pages/authentication/Register"))
// );
// const VerifyCode = Loadable(
//   lazy(() => import("./pages/authentication/VerifyCode"))
// );

// Blog pages

// const BlogPostCreate = Loadable(lazy(() => import('./pages/blog/BlogPostCreate')));
// const BlogPostDetails = Loadable(lazy(() => import('./pages/blog/BlogPostDetails')));
// const BlogPostList = Loadable(lazy(() => import('./pages/blog/BlogPostList')));

// Views pages

const ViewsClasses = Loadable(lazy(() => import("./pages/views/Classes")));
const ViewsClients = Loadable(lazy(() => import("./pages/views/Clients")));
const ViewsTeachers = Loadable(lazy(() => import("./pages/views/Teachers")));
const ViewsOverview = Loadable(lazy(() => import("./pages/views/Overview")));

// Error pages

const AuthorizationRequired = Loadable(
  lazy(() => import("./pages/AuthorizationRequired"))
);
const Forbidden = Loadable(lazy(() => import("./pages/Forbidden")));
const NotFound = Loadable(lazy(() => import("./pages/NotFound")));
const ServerError = Loadable(lazy(() => import("./pages/ServerError")));

const routes: any[] = [
  {
    path: "",
    element: (
      <AuthGuard>
        <ViewsLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/views",
        element: <ViewsOverview />,
      },
      {
        path: "/views/classes",
        element: <ViewsClasses />,
      },
      {
        path: "/views/clients",
        element: <ViewsClients />,
      },
      {
        path: "/views/teachers",
        element: <ViewsTeachers />,
      },
      {
        path: "401",
        element: <AuthorizationRequired />,
      },
      {
        path: "404",
        element: <NotFound />,
      },
      {
        path: "403",
        element: <Forbidden />,
      },
      {
        path: "500",
        element: <ServerError />,
      },
      {
        path: "",
        element: <Navigate to="/views" replace />,
      },
      {
        path: "*",
        element: <Navigate to="/views" replace />,
      },
    ],
  },
];

export default routes;
