import "react-perfect-scrollbar/dist/css/styles.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-quill/dist/quill.snow.css";
import "nprogress/nprogress.css";
import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import StyledEngineProvider from "@material-ui/core/StyledEngineProvider";
import App from "./App";
// import { AuthProvider } from "./contexts/JWTContext";
import { SettingsProvider } from "./contexts/SettingsContext";
import store from "./store";

import { Auth0Provider } from "@auth0/auth0-react";
import { auth0Config } from "./config";
// import { isSafari, isFirefox } from 'react-device-detect';

// const isBrave = async () => {
//   const brave = ((navigator as any).brave && await (navigator as any).brave.isBrave());
//   return brave || false;
// }

// let useRefreshTokens = isSafari || isFirefox || isBrave() ? true : false

ReactDOM.render(
  <StrictMode>
    <HelmetProvider>
      <ReduxProvider store={store}>
        <StyledEngineProvider injectFirst>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SettingsProvider>
              <BrowserRouter>
                <Auth0Provider
                  domain={auth0Config.domain}
                  clientId={auth0Config.client_id}
                  redirectUri={window.location.origin}
                  audience={auth0Config.audience}
                  scope="openid profile email offline analytics"
                  cacheLocation="localstorage" // <-- add this config
                  useRefreshTokens={true}
                >
                  <App />
                </Auth0Provider>
              </BrowserRouter>
            </SettingsProvider>
          </LocalizationProvider>
        </StyledEngineProvider>
      </ReduxProvider>
    </HelmetProvider>
  </StrictMode>,
  document.getElementById("root")
);
