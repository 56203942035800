import { useState } from "react";
import type { FC } from "react";
// import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Box, Button, Container, Grid, Typography } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useAuth0 } from "@auth0/auth0-react";
// import gtm from "../lib/gtm";

const Forbidden: FC = () => {
  const theme = useTheme();
  const { user } = useAuth0();
  const [debug, setDebug] = useState(false);
  const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));

  // useEffect(() => {
  //   gtm.push({ event: "page_view" });
  // }, []);

  return (
    <>
      <Helmet>
        <title>Error: Forbidden | Analytics</title>
      </Helmet>
      <Box
        sx={{
          alignItems: "center",
          backgroundColor: "background.paper",
          display: "flex",
          minHeight: "100%",
          px: 3,
          py: "80px",
        }}
      >
        <Container maxWidth="lg">
          <Typography
            align="center"
            color="textPrimary"
            variant={mobileDevice ? "h4" : "h1"}
          >
            403: Forbidden
          </Typography>
          <Typography
            align="center"
            color="textSecondary"
            sx={{ mt: 0.5 }}
            variant="subtitle2"
          >
            You don't have access to analytics!
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 6,
            }}
          >
            <Box
              alt="Under development"
              component="img"
              src={`/static/error/error401_${theme.palette.mode}.svg`}
              sx={{
                height: "auto",
                maxWidth: "100%",
                width: 400,
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 6,
            }}
          >
            <Grid container>
              <Grid item xs={12} textAlign="center">
                <Button
                  color="primary"
                  onClick={() => {
                    setDebug(!debug);
                  }}
                  variant="outlined"
                >
                  {debug ? "Hide" : "Show"} debug
                </Button>
              </Grid>
              <Grid item xs={12}>
                {debug ? (
                  <pre
                    style={{
                      backgroundColor: theme.palette.background.default,
                      padding: "16px",
                    }}
                  >
                    Permission required `analytics`
                    <br />
                    <br />
                    user: {JSON.stringify(user, null, 2)}
                  </pre>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Forbidden;
